<template lang="pug">
  .page-container.course-management
    portal(to="toolbar-title")
      toolbar-title Q management

    portal(to="toolbar-end")
      CourseAppBarButtons(:activeCity="getActiveCityID")

    .page-container__block
      search-widget(@change="_loadList" :disabled="!$route.query.course_id")

    v-container(fluid data-test="cm-page")
      div.cm-chips-row
        CMChips(
          :active-courses="activeCourses"
          @delete="onDeleteCourseDate"
          portal-chip-name="cm-filters-chips"
        )
        div.cm-chips-row__filters
          LanguageSelectWidget(:disabled="loading" @changed="loadListProcess")
          LicenseTypeSelectWidget(:disabled="loading" @changed="loadListProcess")
      v-card
        .d-flex.cm
          CMSidebar(
            :key="+$route.query.city"
            @mounted="clearActive"
            @coursesLoaded="saveCourses"
            @click:course="loadListProcess"
            ref="sidebar"
          )
          CMBody(
            ref="CMbody"
            :courseDays="courseDays"
            :list="list"
            :loading="loading"
            :candidatesCount="candidatesCount"
            :primaryPagination="primaryPagination"
            :pagination="pagination"
            @change:ordering="_loadList"
            @change:query="_loadList"
            @selectAllToggle="toggleSelectAllItems"
            @needToUpdate:courses="loadCourses"
          )
            template(v-slot:footer="footerOptions")
              .pagination(v-if="pagination")
                div.pagination__more
                  v-btn(
                    color="primary"
                    text
                    class="mb-3 mt-3"
                    data-test="candidate-loadmore"
                    @click="onLoadMoreClick"
                    :loading="loading"
                    v-if="pagination.next"
                  ) load more
                div.pagination__actions
                  per-page-select(@change="onPageSizeChanged")
                  v-pagination(
                    @input="paginationChange"
                    :disabled="loading"
                    :length="pagination.totalPages"
                    v-model="pagination.page"
                    :total-visible="8"
                  )
          course-config(
            v-if="activeCourses && activeCourses.length && !isMultipleMode"
            :key="`${$route.query.city}/${$route.query.course_id || -1}`"
            @limit:update="loadCourses"
            @notes:update="loadCourses"
            @update:exam="_loadList"
          )
      log
        template(v-slot:action="log")
          span
            v-btn(color="primary" fab @click="log.loadLogs" data-test="log-btn").btn-floating
              v-icon(color="white") mdi-post
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"

import errorsMixin from '@/mixins/errors.mixin'
import Svc from "./core/cm-service"
import CandidatesService from "@/app/admin/modules/candidates/core/candidates-service"
import { CANDIDATE_PAGE } from '../candidates/core/candidates-const'
import CandidatesLog from "@/app/admin/modules/candidates/components/CandidatesLog.vue"
import CandidatesCount from "@/app/admin/modules/candidates/core/models/candidatesCountModel"
import CandidateBroadcast from "@/app/admin/modules/candidates/core/models/candidateBroadcast"
import paginationMixin from '@/mixins/pagination.mixin'
import PaginationModel from '../../../../models/paginationModel'
import Logs from "@/app/admin/components/Log.vue"
import examBroadcastMixin from "@/mixins/examBroadcast.mixin"
import CourseSvc from "@/app/admin/models/courseService"
import courseApi from "@/services/api/api-courses"
import { dateFormating } from '@/util'
import { LOAD_DEFAULT_CITY_ID } from "@/util/const"
import LicenseTypeSelectWidget from "@/components/widget/LicenseTypeSelectWidget.vue";
import LanguageSelectWidget from "@/components/widget/LanguageSelectWidget.vue";

export default {
  name: "QManagementPage",

  mixins: [errorsMixin, paginationMixin, examBroadcastMixin],

  data: () => ({
    candidatesSvc: new CandidatesService(),
    loading: false,
    list: [],
    fullListIds: [],
    svc: new Svc(),
    courses: {},
    candidatesCount: new CandidatesCount(),
    pagination: null,
    primaryPagination: null,
    logs: null,
    logsShowing: false,
    logActive: true,
    activeCourse: null,
    courseDays: []
  }),

  async created() {
    if (!this.$route.query.city) await this.$router.replace({query: {city: 4}})
    await this.initCandidateBroadcast()
  },

  computed: {
    ...mapGetters({
      currentUser: 'user/current',
    }),
    ...mapState('cm', {
      activeCourses: state => state.activeCourses,
      isMultipleMode: state => state.isMultipleMode
    }),
    getActiveCityID() {
      if (this.$route.query.city) return +this.$route.query.city
      else return LOAD_DEFAULT_CITY_ID
    }
  },

  mounted() {
    this.loadCBRUser({cache: true})
    this.loadCandidateColors()
  },

  methods: {
    ...mapActions({
      loadCBRUser: 'cbrUser/list',
      loadCandidateColors: 'crmCandidateColors/colorsList',
    }),

    ...mapMutations({
      unSelectCourse: 'cm/unSelectCourse',
      unSelectAllCourses: 'cm/unSelectAllCourses',
    }),

    async onPageSizeChanged(size) {
      await this.updatePageSize(size);
      this._loadList({})
    },

    saveCourses(courses) {
      this.courses = courses
      this.list = []
    },

    loadListProcess() {
      if (this.activeCourses.length) {
        this._loadList({})
        if (!this.isMultipleMode) {
          this.loadCourseDays()
        }
      } else {
        this.clearList()
      }
    },

    async loadCourseDays() {
      try {
        if (!this.activeCourses[0].date) return
        let activeCourseDate = dateFormating(this.activeCourses[0].date).join('-')
        let res = await new CourseSvc(courseApi).courseDays({date: activeCourseDate, city: this.$route.query.city})
        this.courseDays = res.results
      } catch (e) {
        this.processError(e, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    async onDeleteCourseDate(data) {
      this.unSelectCourse(data)
      await this.$refs.sidebar.setDateQuery()
      if (this.activeCourses.length) {
        this._loadList()
      } else {
        this.clearList()
      }
    },

    clearActive() {
      this.unSelectAllCourses()
    },

    clearList() {
      this.list = [];
      this.candidatesCount = new CandidatesCount();
    },

    async _loadList(item = {more: false}) {
      try {
        let page = this.$route.query.page_name ? this.$route.query.page_name : CANDIDATE_PAGE.RESERVED_ALL
        this.loading = true
        let { results, pagination, candidates_counts } = await this.candidatesSvc.list(this.$route.query, page)
        this.pagination = new PaginationModel(this.setPaginationParams(pagination))
        this.candidatesCount.set(candidates_counts)
        if (item.more) results.forEach((item) => setTimeout(() => this.list.push(item), 0))
        else {
          this.list = []
          results.forEach((item) => setTimeout(() => this.list.push(item), 0))
          this.setPrimaryPagination()
        }
        this.clearFullListIds();
        this.loading = false
        this.$emit('candidatesCountsChanged', candidates_counts)
      } catch (error) {
        console.log(error)
        this.loading = false
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    async toggleSelectAllItems(clear) {
      if (clear) {
        this.$refs.CMbody.setActiveItems([]);
      } else {
        if (!this.fullListIds.length) {
          await this._loadList()
          await this._getFullListIds();
        }
        this.$refs.CMbody.setActiveItems(this.fullListIds);
      }
    },

    async _getFullListIds() {
      const page = this.$route.query.page_name ? this.$route.query.page_name : CANDIDATE_PAGE.RESERVED_ALL
      if (this.pagination.count > this.pagination.size) {
        try {
          this.fullListIds = await this.candidatesSvc.fullListIds(
            {
              ids_list: true,
              ...this.$route.query
            },
            page
          );
        } catch (error) {
          console.log(error)
          this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
        }
      } else {
        this.fullListIds = [];
      }
    },

    setPrimaryPagination() {
      this.primaryPagination = {
        page: this.pagination.page,
        size: this.pagination.size
      }
    },

    async paginationChange() {
      await this.setPaginationQuery()
      this._loadList({})
    },

    async onLoadMoreClick() {
      this.pagination.loadMore()
      await this.setPaginationQuery()
      this._loadList({more: true})
    },

    async initCandidateBroadcast() {
      let tokens
      tokens = await this.$auth.getTokensFromRepoOrRefresh()
      this.broadcast = new CandidateBroadcast(null, tokens.access)
      this.broadcast.startListen(this.getBroadcastData)
    },

    getBroadcastData() {
      return this.list
    },

    loadCourses() {
      this.$refs.sidebar.loadData()
    },

    clearFullListIds() {
      this.fullListIds = [];
    },
  },

  beforeRouteLeave(to, from, next) {
    this.$store.commit('cm/clearAll')
    this.broadcast.close()
    this.examBroadcast.close()
    next()
  },

  provide: function() {
    return {
      svc: this.svc,
      candidatesSvc: this.candidatesSvc,
      getDefaultPagination: this.getDefaultPagination,
    }
  },

  components: {
    LanguageSelectWidget,
    LicenseTypeSelectWidget,
    toolbarTitle: () => import('@/components/global/toolbar/ToolbarTitle.vue'),
    searchWidget: () => import('@/components/widget/SearchWidget.vue'),
    CMSidebar: () => import('./components/CMCSidebarSelector.vue'),
    CMBody: () => import('./components/CMBody.vue'),
    CourseConfig: () => import('./components/CMCourseConfig.vue'),
    CMChips: () => import('./components/CMChips.vue'),
    perPageSelect: () => import('@/app/admin/components/PerPageSelect.vue'),
    logsTabs: () => import ('@/app/admin/modules/candidates/components/candidates/CandidatesLogTabs.vue'),
    CourseAppBarButtons: () => import('@/app/admin/modules/course_day/components/sidebar/CourseAppBarButtons.vue'),
    logs: Logs,
    log: CandidatesLog
  },

  watch: {
    '$route.query.city'() {
      this.candidatesCount = new CandidatesCount()
      this.pagination = null
      this.activeCourse = null
    },
    '$route.query.license_type'() {
      this.candidatesCount = new CandidatesCount()
      this.pagination = null
    //   this.activeCourse = null
    },
    '$route.query.language'() {
      this.candidatesCount = new CandidatesCount()
      this.pagination = null
    //   this.activeCourse = null
    },
  }
}
</script>

<style lang="scss">
.pagination {
  position: relative;
}

.online-courses-mode {
  margin-left: 20px;

  .v-input__slot {
    margin-bottom: 0 !important;
    .v-label {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .v-messages {
    display: none;
  }
}

.cm-header-cell {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  height: 64px;
  border-bottom: 1px solid $border-color;
}

.cm-spacing {
  padding-left: 24px;
  padding-right: 16px;
}

.cm {
  & > div {
    border-right: 1px solid $border-color;

    &:last-child {
      border-right: unset;
    }
  }
}

.cm-area {
  height: calc(100vh - 135px);
  overflow-y: auto;
}
</style>


<style lang="scss" scoped>
.cm-chips-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  &__filters {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    gap: 8px;
  }
}
</style>