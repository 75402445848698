<template lang="pug">
  div.languages-filter
    app-select(
      :items="LANGUAGES"
      label="Language"
      item-value='slug'
      item-text='name'
      :clearable="true"
      :multiple="true"
      :show-close-menu-btn="false"
      :show-clear-btn="false"
      :value="value"
      @change="updateValue($event)"
      @clear="updateValue()"
    )
</template>

<script>
import AppSelect from "@/components/global/fields/appSelect.vue";
import {LANGUAGES} from "@/util/const";

export default {
  components: {AppSelect},

  props: {
    disabled: Boolean
  },

  data() {
    return {
      LANGUAGES,
      licenseType: null
    }
  },

  computed: {
    value() {
      return (
        Array.isArray(this.$route.query.language) ?
          this.$route.query.language :
          [this.$route.query.language]
      ).map(e => {
        return LANGUAGES.find(item => item.slug === e)
      });
    }
  },

  methods: {
    updateValue(value) {
      const queryUpdated = {
        ...this.$route.query,
        page: 1,
        language: value || undefined
      };
      // delete queryUpdated.course_id;
      // delete queryUpdated.page_name;
      // delete queryUpdated.size;
      this.$router.replace({query: queryUpdated})
      this.$emit('changed')
    },
  }
}
</script>

<style lang="scss" scoped>
.languages-filter {
  margin-left: 8px;
  margin-top: 8px;
  flex-shrink: 0;
}
</style>